import * as React from 'react';
import Layout from '../../components/Layout';
import Products from './Products';

const ProductPage = () => {
  return (
    <Layout>
      <Products />
    </Layout>
  );
};

export default ProductPage;
